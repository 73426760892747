import * as React from "react"
import { useAuth } from "@msc-insure/use-auth"
import { AppLoading } from "../components/shell"

const AuthPage = () => {
  const { authenticateFromCallback } = useAuth()

  React.useEffect(() => {
    authenticateFromCallback({ postLoginRoute: "/app" })
  }, [authenticateFromCallback])

  return (
    <AppLoading />
  )
}

export default AuthPage
